import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1280.000000 1280.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)" >
				<path d="M6245 9414 c-104 -63 -169 -101 -215 -126 -44 -25 -401 -230 -430
-247 -19 -12 -357 -207 -414 -239 -22 -13 -70 -41 -106 -62 -36 -21 -121 -70
-190 -110 -69 -40 -165 -96 -215 -125 -49 -29 -106 -62 -126 -73 -19 -11 -95
-54 -169 -97 -74 -43 -148 -86 -166 -96 -18 -10 -51 -29 -73 -44 l-41 -25 0
-1279 0 -1279 136 -79 c155 -88 181 -103 314 -180 52 -31 109 -63 125 -73 81
-46 491 -283 569 -328 49 -29 95 -52 102 -52 7 0 14 -4 16 -9 3 -8 133 -91
143 -91 3 0 22 -11 43 -23 20 -13 58 -36 84 -50 26 -14 118 -67 205 -118 87
-50 187 -108 223 -129 36 -20 106 -61 155 -90 l90 -53 55 30 c30 16 101 56
157 89 l103 59 0 268 c0 147 -2 267 -6 267 -3 0 -49 -25 -102 -57 -126 -73
-184 -103 -200 -103 -8 0 -41 16 -73 35 -33 19 -62 35 -65 35 -2 0 -21 11 -42
23 -20 13 -57 35 -82 49 -61 34 -345 199 -448 259 -45 27 -84 49 -87 49 -2 0
-40 23 -84 50 -44 28 -83 50 -85 50 -3 0 -29 14 -58 32 -29 17 -109 64 -178
103 -69 40 -181 105 -250 145 -69 40 -162 94 -207 119 l-83 46 0 1006 0 1007
68 40 c37 22 88 52 114 66 26 14 97 55 158 91 61 36 115 65 120 65 4 0 10 4
12 8 2 5 28 22 58 38 106 58 535 307 570 330 19 13 38 24 43 24 4 0 36 17 70
38 34 21 100 60 147 87 47 27 150 86 230 133 120 69 148 82 166 74 11 -6 108
-61 215 -123 107 -61 208 -119 224 -127 17 -8 41 -23 55 -32 14 -9 45 -28 70
-42 25 -14 101 -58 170 -98 69 -39 181 -104 250 -143 69 -39 127 -75 128 -79
2 -4 8 -8 12 -8 5 0 59 -29 121 -65 61 -36 137 -80 168 -97 31 -16 70 -39 86
-49 17 -11 73 -44 125 -74 l95 -53 5 -176 5 -176 65 38 c36 21 108 63 160 94
52 31 113 66 135 78 22 12 55 31 73 42 l32 20 0 174 0 174 -38 24 c-33 21
-455 264 -552 319 -19 10 -75 43 -125 72 -87 51 -255 148 -400 232 -38 23 -88
51 -110 63 -22 12 -80 46 -130 75 -49 29 -220 129 -380 221 -159 92 -332 191
-382 221 -51 29 -94 53 -95 53 -2 0 -30 -17 -63 -36z"/>
<path d="M5510 6895 l0 -1055 125 0 125 0 2 200 3 200 115 -152 c63 -83 120
-156 125 -162 6 -6 23 -28 39 -48 l28 -38 159 0 159 0 -107 136 c-60 74 -117
148 -128 163 -11 15 -55 71 -98 124 l-78 97 33 48 c17 26 82 115 143 197 62
83 129 175 151 206 l39 55 -3 542 -2 542 -130 0 -130 0 -2 -267 -3 -268 -51
75 c-27 41 -110 162 -184 268 l-133 192 -99 0 -98 0 0 -1055z m301 443 c18
-29 36 -55 40 -58 6 -5 30 -38 184 -262 l95 -138 -39 0 c-43 0 -40 3 -186
-204 -55 -78 -136 -190 -141 -195 -2 -2 -4 201 -4 452 0 263 4 457 9 457 5 0
24 -24 42 -52z"/>
<path d="M8166 5940 c-38 -22 -70 -40 -72 -40 -2 0 -32 -17 -66 -38 -35 -21
-117 -69 -183 -107 -66 -37 -140 -80 -165 -95 -25 -15 -65 -39 -90 -52 -25
-14 -90 -51 -145 -83 -55 -32 -113 -66 -130 -74 -16 -9 -32 -18 -35 -21 -3 -3
-16 -11 -29 -19 -25 -14 -178 -102 -233 -134 -36 -21 -35 -21 -147 45 -34 21
-67 38 -72 38 -5 0 -9 -160 -9 -375 0 -206 2 -375 4 -375 4 0 157 88 406 232
482 279 620 358 629 358 5 0 11 4 13 8 2 4 53 36 113 71 61 34 117 66 125 71
79 48 153 90 156 90 2 0 39 21 82 46 130 78 183 109 280 164 50 29 92 55 92
59 0 3 -24 19 -53 35 -28 16 -84 49 -124 72 -40 24 -107 63 -150 87 -43 24
-89 51 -103 60 -25 16 -28 16 -94 -23z"/>
<path d="M8235 3571 c-6 -5 -189 -9 -795 -15 -305 -3 -658 -11 -785 -17 -126
-5 -347 -12 -490 -14 -718 -13 -859 -16 -967 -25 -64 -6 -181 -10 -260 -10
-213 0 -636 -28 -678 -45 -19 -8 -48 -16 -65 -18 -16 -1 40 -6 125 -10 281
-14 835 -18 2490 -16 14 0 228 -4 475 -8 248 -4 554 -6 680 -4 194 3 237 1
269 -13 43 -18 59 -21 36 -6 -12 8 -11 10 7 10 12 0 23 -5 25 -11 3 -9 10 -9
27 0 15 9 40 10 74 5 113 -15 207 1 207 36 0 11 9 24 21 30 14 8 18 17 13 28
-3 9 -6 27 -5 39 0 13 -3 22 -7 21 -9 -3 -110 18 -180 38 -46 13 -205 17 -217
5z"/>

</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
